<template>
  <div>
    <v-btn class="mt-2" small color="secondary " @click="dialogOpen = true">
      Assign to Consultant</v-btn
    >
    <v-dialog v-model="dialogOpen" max-width="800px">
      <v-card
        ><v-card-title> <h2>Assign client's to a consultant</h2> </v-card-title>
        <div class="pa-4">
          <p :style="{ textAlign: 'center' }">
            Select the client's from the table first
          </p>
          <new-consultant-picker @consultant-selected="setConsultantId" />
        </div>

        <div class="pa-2 text-right">
          <v-btn color="secondary" @click="dialogOpen = false">Cancel</v-btn>
          <v-btn color="primary" @click="updateUsersConsultant">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import Swal from "sweetalert2";
import NewConsultantPicker from "../../../components/Base/Pickers/NewConsultantPicker.vue";

export default {
  name: "AssignUserToConsultant",
  components: { NewConsultantPicker },
  props: {
    userIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogOpen: false,
      consultantId: null,
    };
  },
  methods: {
    openTheDialog() {
      this.dialogOpen = true;
    },
    closeTheDialog() {
      this.dialogOpen = false;
    },
    setConsultantId(consultantId) {
      // Set the selected consultantId
      this.consultantId = consultantId;
    },
    async updateUsersConsultant() {
      try {
        if (!this.consultantId) {
          // Handle if consultant is not selected
          Swal.fire({
            icon: "error",
            title: "Select Consultant",
            text: "Please select a consultant before saving.",
          });
          return;
        }

        const UPDATE_USERS_CONSULTANT = gql`
          mutation UpdateUsersConsultant(
            $userIds: [String!]!
            $consultantId: String!
          ) {
            assignUsersToConsults(
              userIds: $userIds
              consultantId: $consultantId
            ) {
              success
              message
            }
          }
        `;

        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_USERS_CONSULTANT,
          variables: {
            userIds: this.userIds,
            consultantId: this.consultantId,
          },
        });

        if (data && data.assignUsersToConsults.success) {
          Swal.fire({
            icon: "success",
            title: "Consultants assigned",
            text: "Clients have been successfully assigned to their consultant.",
          })
            .then(() => {
              // This code will be executed after the user clicks "OK"
              this.$emit("consultant-assigned");
            })
            .finally(() => {
              // This code will be executed regardless of whether the Swal modal is closed or not
              this.dialogOpen = false;
            });
        } else {
          console.error("Failed to assign users");
          // Handle failed mutation
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Failed to assign users. Please try again later.",
          });
        }
      } catch (error) {
        console.error("Error updating users consultant:", error);
        // Handle error
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while updating users. Please try again later.",
        });
      }
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
